<template>
    <div class="bg-white px-8 py-6">
        <Spinners v-if="isLoading" />
        <b-notification
            v-else-if="submitSucess"
            type="is-success"
            has-icon
            aria-close-label="Close notification"
            @close="handleCancel"
        >
            Profile updated.
        </b-notification>
        <b-notification
            v-else-if="submitError"
            type="is-danger"
            has-icon
            aria-close-label="Close notification"
            @close="resetSubmitFlags"
        >
            <b>Sorry</b>, there was an error updating the profile.
        </b-notification>
        <form v-else @submit.prevent="handleSubmit">
            <h2>Edit Profile</h2>

            <div class="mb-4">
                <label
                    :for="`email-${_uid}`"
                    class="block text-sm font-bold mb-2"
                >
                    Email
                </label>
                <input
                    v-model="updatePayload.email"
                    :id="`email-${_uid}`"
                    class="appearance-none border rounded w-full py-2 px-3 leading-tight"
                    type="email"
                />
            </div>
            <fieldset class="mb-4">
                <div class="flex items-start text-sm mb-2">
                    <div class="flex items-center">
                        &#8203;
                        <input
                            :id="`displayEmail-${_uid}`"
                            v-model="updatePayload.displayEmail"
                            type="checkbox"
                            class="form-checkbox border h-4 w-4"
                            autocomplete="off"
                        />
                    </div>
                    <label :for="`displayEmail-${_uid}`" class="ml-1"
                        >Display email</label
                    >
                </div>
            </fieldset>

            <div class="mb-4">
                <label
                    :for="`title-${_uid}`"
                    class="block text-sm font-bold mb-2"
                >
                    Title
                </label>
                <input
                    v-model="updatePayload.title"
                    :id="`title-${_uid}`"
                    class="appearance-none border rounded w-full py-2 px-3 leading-tight"
                    type="text"
                />
            </div>
            <div class="mb-4">
                <label
                    :for="`phone-${_uid}`"
                    class="block text-sm font-bold mb-2"
                >
                    Phone
                </label>
                <input
                    v-model="updatePayload.phone"
                    :id="`phone-${_uid}`"
                    inputmode="numeric"
                    class="appearance-none border rounded w-full py-2 px-3 leading-tight"
                    type="text"
                />
            </div>
            <fieldset class="mb-4">
                <div class="flex items-start text-sm mb-2">
                    <div class="flex items-center">
                        &#8203;
                        <input
                            :id="`displayPhone-${_uid}`"
                            v-model="updatePayload.displayPhone"
                            type="checkbox"
                            class="form-checkbox border h-4 w-4"
                            autocomplete="off"
                        />
                    </div>
                    <label :for="`displayPhone-${_uid}`" class="ml-1"
                        >Display Phone number</label
                    >
                </div>
            </fieldset>
            <div class="mb-4">
                <label
                    :for="`linkedIn-${_uid}`"
                    class="block text-sm font-bold mb-2"
                >
                    LinkedIn
                </label>
                <input
                    v-model="updatePayload.linkedIn"
                    :id="`linkedIn-${_uid}`"
                    class="appearance-none border rounded w-full py-2 px-3 leading-tight"
                    type="url"
                    placeholder="https://..."
                    pattern="https://.*"
                    size="30"
                />
            </div>
            <div class="mb-4">
                <label
                    :for="`facebook-${_uid}`"
                    class="block text-sm font-bold mb-2"
                >
                    Facebook
                </label>
                <input
                    v-model="updatePayload.facebook"
                    :id="`facebook-${_uid}`"
                    class="appearance-none border rounded w-full py-2 px-3 leading-tight"
                    type="url"
                    placeholder="https://..."
                    pattern="https://.*"
                    size="30"
                />
            </div>
            <div class="mb-4">
                <label
                    :for="`twitter-${_uid}`"
                    class="block text-sm font-bold mb-2"
                >
                    Twitter
                </label>
                <input
                    v-model="updatePayload.twitter"
                    :id="`twitter-${_uid}`"
                    class="appearance-none border rounded w-full py-2 px-3 leading-tight"
                    type="url"
                    placeholder="https://..."
                    pattern="https://.*"
                    size="30"
                />
            </div>
            <div class="mb-4">
                <label
                    :for="`instagram-${_uid}`"
                    class="block text-sm font-bold mb-2"
                >
                    Instagram
                </label>
                <input
                    v-model="updatePayload.instagram"
                    :id="`instagram-${_uid}`"
                    class="appearance-none border rounded w-full py-2 px-3 leading-tight"
                    type="url"
                    placeholder="https://..."
                    pattern="https://.*"
                    size="30"
                />
            </div>
            <div class="mb-4">
                <label
                    :for="`snapchat-${_uid}`"
                    class="block text-sm font-bold mb-2"
                >
                    Snapchat
                </label>
                <input
                    v-model="updatePayload.snapchat"
                    :id="`snapchat-${_uid}`"
                    class="appearance-none border rounded w-full py-2 px-3 leading-tight"
                    type="url"
                    placeholder="https://..."
                    pattern="https://.*"
                    size="30"
                />
            </div>
            <div class="mb-4">
                <label
                    :for="`youTube-${_uid}`"
                    class="block text-sm font-bold mb-2"
                >
                    YouTube
                </label>
                <input
                    v-model="updatePayload.youTube"
                    :id="`youTube-${_uid}`"
                    class="appearance-none border rounded w-full py-2 px-3 leading-tight"
                    type="url"
                    placeholder="https://..."
                    pattern="https://.*"
                    size="30"
                />
            </div>
            <div class="mb-4" v-for="field in bioExtraFields" :key="field">
                <label
                    :for="`${field}-${_uid}`"
                    class="block text-sm font-bold mb-2"
                >
                    {{ specialSectionLabels[field] }}
                </label>
                <textarea
                    v-model="updatePayload[field]"
                    :id="`${field}-${_uid}`"
                    class="appearance-none border rounded w-full py-2 px-3 leading-tight"
                    rows="5"
                    :placeholder="specialSectionPlaceholders[field]"
                ></textarea>
            </div>
            <fieldset
                v-if="sectionToUse.primaryInterests && interestOptions.length"
                class="mb-4"
            >
                <legend class="block text-sm font-bold mb-2">
                    Interests
                </legend>
                <div v-for="(item, index) in primaryInterests" :key="index">
                    <div class="flex items-start text-sm mb-2">
                        <div class="flex items-center">
                            &#8203;
                            <input
                                :id="`interest-${_uid}-${index}`"
                                v-model="updatePayload.specialInterests"
                                :value="item"
                                type="checkbox"
                                class="form-checkbox border h-4 w-4"
                                autocomplete="off"
                            />
                        </div>
                        <label
                            :for="`interest-${_uid}-${index}`"
                            class="ml-1"
                            >{{ item }}</label
                        >
                    </div>
                </div>
            </fieldset>

            <div class="md:flex items-center justify-between">
                <div class="my-2">
                    <button
                        @click="handleCancel"
                        type="reset"
                        class="block w-full button pill-button button-clear clear-button has-text-weight-bold is-uppercase"
                    >
                        Cancel
                    </button>
                </div>
                <div class="my-2">
                    <button
                        class="block w-full button pill-button is-primary has-text-weight-bold is-uppercase"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { getModule } from "vuex-module-decorators";
import attendeeVuexModule from "@/store/vuex-modules/attendees";
import eventHub from "@/event-hub";
import Spinners from "@/components/utilities/Spinners.vue";

const attendeeStore = getModule(attendeeVuexModule);

export default {
    name: "AttendeeProfileEditForm",
    components: {
        Spinners
    },
    data() {
        return {
            isLoading: false,
            submitSucess: false,
            submitError: false,
            valueForReset: {},
            updatePayload: {
                email: "",
                title: "",
                phone: "",
                linkedIn: "",
                facebook: "",
                twitter: "",
                instagram: "",
                snapchat: "",
                youTube: "",
                myAvailability: "",
                biography: "",
                aboutOrganization: "",
                displayPhone: false,
                displayEmail: false,
                specialInterests: []
            },
            interestOptions: [
                "Sponsor Models",
                "Leadership and Strategy",
                "Conferences/Expos",
                "New Member Benefits",
                "Any Topic",
                "Corporate Sponsorships",
                "Certifications or Credentialing",
                "Events (Not Conferences)",
                "Acquisitions and Mergers"
            ]
        };
    },

    computed: {
        pageOptions() {
            return this.$store.getters.getPageOptions("attendees");
        },

        sectionToUse() {
            return this.pageOptions.section;
        },

        attendeeInfo() {
            return attendeeStore.attendee;
        },

        primaryInterests() {
            const interest = this.$store.getters.getSpecialInterest.map(
                (item) => item.name
            );

            return interest.sort();
        },

        bioExtraFields() {
            return attendeeStore.bioExtraFields;
        },

        specialSectionLabels() {
            return {
                myAvailability: this.pageOptions.fieldLabels?.myAvailability
                    ? this.pageOptions.fieldLabels.myAvailability
                    : "My availability",
                biography: this.pageOptions.fieldLabels?.biography
                    ? this.pageOptions.fieldLabels.biography
                    : "Biography",
                aboutOrganization: this.pageOptions.fieldLabels?.organization
                    ? this.pageOptions.fieldLabels.organization
                    : "About my organization"
            };
        },

        specialSectionPlaceholders() {
            return {
                myAvailability: this.pageOptions.fieldPlaceholders
                    ?.myAvailability
                    ? this.pageOptions.fieldPlaceholders.myAvailability
                    : "Please share what times / time zone you're available for scheduling meetings",
                biography: this.pageOptions.fieldPlaceholders?.biography
                    ? this.pageOptions.fieldPlaceholders.biography
                    : "",
                aboutOrganization: this.pageOptions.fieldPlaceholders
                    ?.organization
                    ? this.pageOptions.fieldPlaceholders.organization
                    : ""
            };
        }
    },

    created() {
        this.initData();

        eventHub.$on("attendee-update-started", this.handleUpdateStarted);
        eventHub.$on("attendee-update-success", this.handleUpdateSuccess);
        eventHub.$on("attendee-update-error", this.handleUpdateError);
        eventHub.$on("attendee-update-done", this.handleUpdateDone);
    },

    beforeDestroy() {
        this.resetSubmitFlags();
        this.setUpdatePayload();

        eventHub.$off("attendee-update-started", this.handleUpdateStarted);
        eventHub.$off("attendee-update-success", this.handleUpdateSuccess);
        eventHub.$off("attendee-update-error", this.handleUpdateError);
        eventHub.$off("attendee-update-done", this.handleUpdateDone);
    },

    methods: {
        initData() {
            const attendeeData = JSON.parse(
                JSON.stringify(attendeeStore.attendee)
            );

            const {
                displayPhone,
                displayEmail,
                email,
                phone,
                title,
                specialInterests,
                linkedIn,
                facebook,
                twitter,
                instagram,
                snapchat,
                youTube,
                myAvailability,
                biography,
                aboutOrganization,
                ...x
            } = attendeeData;

            // nothing to do with the following.
            // handling typescript warning about unused vars.
            x;

            const dataForReset = {
                displayPhone,
                displayEmail,
                email,
                phone,
                title,
                specialInterests,
                linkedIn,
                facebook,
                twitter,
                instagram,
                snapchat,
                youTube,
                myAvailability,
                biography,
                aboutOrganization
            };

            let dataForUpdate = JSON.parse(
                JSON.stringify(attendeeStore.attendeeUpdatePayload)
            );

            if (0 === Object.keys(dataForUpdate).length) {
                dataForUpdate = dataForReset;
            }

            if (!dataForUpdate.specialInterests) {
                dataForUpdate.specialInterests = [];
            }

            this.valueForReset = dataForReset;
            this.updatePayload = dataForUpdate;
        },
        resetData() {
            attendeeStore.setAttendeeUpdatePayload({});
            this.initData();
            this.updatePayload = this.valueForReset;
        },
        resetSubmitFlags() {
            this.isLoading = false;
            this.submitSucess = false;
            this.submitError = false;
        },
        setUpdatePayload() {
            const payload = this.updatePayload;
            attendeeStore.setAttendeeUpdatePayload(payload);
        },
        handleCancel() {
            this.resetData();
            this.$emit("cancel");
        },
        handleSubmit() {
            this.setUpdatePayload();
            this.$emit("submit");
        },
        handleUpdateStarted() {
            this.isLoading = true;
        },
        handleUpdateSuccess() {
            this.submitSucess = true;
            this.resetData();
        },
        handleUpdateError() {
            this.submitError = true;
        },
        handleUpdateDone() {
            this.isLoading = false;
        }
    }
};
</script>
