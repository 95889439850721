


























































































































































































































































































































































































































































import { a11yFixBuefyModalAriaAttrs } from "@/services/a11y";

import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import eventHub from "@/event-hub";

import attendeeVuexModule from "@/store/vuex-modules/attendees";
import layoutImagesVuexModule from "@/store/vuex-modules/layoutImages";

import AttendeePrimaryInterest from "@/components/attendees/AttendeePrimaryInterest.vue";
import NotFound from "@/views/NotFound.vue";
import AttendeeAvatarEditForm from "@/components/attendee-profile/AttendeeAvatarEditForm.vue";
import AttendeeProfileEditForm from "@/components/attendee-profile/AttendeeProfileEditForm.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import svgLinkedin from "@/components/svg/svg-linkedin.vue";
import svgInstagram from "@/components/svg/svg-instagram.vue";
import svgFacebook from "@/components/svg/svg-facebook.vue";
import svgTwitter from "@/components/svg/svg-twitter.vue";
import svgYoutube from "@/components/svg/svg-youtube.vue";
import svgSnapchat from "@/components/svg/svg-snapchat.vue";
import svgCalendar from "@/components/svg/svg-mini-calendar.vue";
import svgMessage from "@/components/svg/svg-message.vue";

const layoutImagesStore = getModule(layoutImagesVuexModule);
const attendeeStore = getModule(attendeeVuexModule);

@Component({
    components: {
        Spinners,
        NotFound,
        AttendeeAvatarEditForm,
        AttendeeProfileEditForm,
        AttendeePrimaryInterest,
        svgLinkedin,
        svgInstagram,
        svgFacebook,
        svgTwitter,
        svgYoutube,
        svgSnapchat,
        svgMessage,
        svgCalendar
    },
    directives: {
        addbuefymodal: {
            bind: function(el) {
                eventHub.$emit("BuefyModalBound", el);
            }
        }
    }
})
export default class AttendeeProfile extends Vue {
    @Prop(String)
    id!: string;

    isLoading = true;
    isAvatarModalOpen = false;
    isModalOpen = false;

    // Getters
    get layoutOptions() {
        return this.$store.getters.layoutOptions;
    }

    get sectionToUse() {
        return this.$store.getters.getPageOptions("attendees");
    }
    get layoutImageAttendee() {
        return layoutImagesStore.getLayoutImage("attendee");
    }

    get awsConfig() {
        return this.$store.getters.awsConfig;
    }

    get attendeeData() {
        return attendeeStore.attendee;
    }
    get attendeeAvatarImage() {
        return attendeeStore.avatarImage;
    }
    get attendeeAvatarFileName() {
        return attendeeStore.avatarFileName;
    }
    get hasSocialMediaLinks() {
        return Boolean(
            this.attendeeData.linkedIn ||
                this.attendeeData.instagram ||
                this.attendeeData.facebook ||
                this.attendeeData.twitter ||
                this.attendeeData.snapchat ||
                this.attendeeData.youTube
        );
    }

    get addCityStateToOrgName() {
        return this.sectionToUse.addCityStateToOrgName
            ? this.sectionToUse.addCityStateToOrgName
            : false;
    }

    get modifiedOrgName() {
        return this.addCityStateToOrgName
            ? [
                  this.attendeeData.companyName,
                  this.attendeeData.city,
                  this.attendeeData.state
              ]
                  .filter((item) => item && item != "")
                  .join(", ")
            : this.attendeeData.companyName;
    }

    get sectionLabels() {
        return {
            myAvailability: this.sectionToUse.fieldLabels?.myAvailability
                ? this.sectionToUse.fieldLabels.myAvailability
                : "My availability",
            biography: this.sectionToUse.fieldLabels?.biography
                ? this.sectionToUse.fieldLabels.biography
                : "Biography",
            aboutOrganization: this.sectionToUse.fieldLabels?.aboutOrganization
                ? this.sectionToUse.fieldLabels.aboutOrganization
                : "About my organization",
            companyName: this.sectionToUse.fieldLabels?.companyName
                ? this.sectionToUse.fieldLabels.companyName
                : "Organization",
            companyType: this.sectionToUse.fieldLabels?.companyType
                ? this.sectionToUse.fieldLabels.companyType
                : "Organization Type",
            title: this.sectionToUse.fieldLabels?.title
                ? this.sectionToUse.fieldLabels.title
                : "Title",
            specialInterests: this.sectionToUse.fieldLabels?.specialInterests
                ? this.sectionToUse.fieldLabels.specialInterests
                : "Special Interests",
            address: this.sectionToUse.fieldLabels?.address
                ? this.sectionToUse.fieldLabels.address
                : "Address",
            system: this.sectionToUse.fieldLabels?.system
                ? this.sectionToUse.fieldLabels.system
                : "System"
        };
    }

    get avatarUrl() {
        if (!this.attendeeData) return "";

        if (attendeeStore.attendeeAvatarUrl) {
            return attendeeStore.attendeeAvatarUrl;
        } else {
            return this.attendeeData.imgPath
                ? this.attendeeData.imgPath
                : require("../../assets/avatar2.png");
        }
    }

    get userInfo() {
        return this.$store.getters.userInfo;
    }

    get canEdit() {
        const user = this.$store.getters.user;
        const isSuperUser = this.$store.getters.isSuperUser;
        return Boolean(isSuperUser || user.id === this.attendeeData.attendeeId);
    }

    get attendeeApiOptions() {
        const id = this.id || this.userInfo.id;
        const options = { id: id, isDemoData: false };

        return options;
    }

    get attendeeDisplayName() {
        let parts = [];

        if (this.sectionToUse.useMiddleInitial) {
            parts = [
                this.attendeeData.firstName,
                this.attendeeData.MI,
                this.attendeeData.lastName
            ];
        } else {
            parts = [this.attendeeData.firstName, this.attendeeData.lastName];
        }

        if (this.sectionToUse.usePrefixAndSuffix) {
            parts.unshift(this.attendeeData.prefix);
        }

        let name = parts
            .filter((part: string | undefined) => {
                return part && part.trim() != "";
            })
            .join(" ");

        if (
            this.sectionToUse.usePrefixAndSuffix &&
            this.attendeeData.suffix &&
            this.attendeeData.suffix.trim() != ""
        ) {
            name += `, ${this.attendeeData.suffix}`;
        }

        return name;
    }

    get buttonClasses() {
        return this.useIconButtons
            ? "bg-accent-alt rounded-full h-12 w-12 grid duration-300 transition-transform transform hover:scale-125"
            : "button pill-button is-primary is-size-4 py-2 has-text-weight-bold uppercase";
    }

    get useIconButtons() {
        return this.layoutOptions.usesAttendeeIconButtons
            ? this.layoutOptions.usesAttendeeIconButtons
            : true;
    }

    get usesCreateActivityControl() {
        const optionChecker = this.$store.getters.isPageOptionActiveInEnv;
        return optionChecker("createActivity", "isActive");
    }

    get useSpecialInterests() {
        return this.sectionToUse.section?.primaryInterests
            ? this.sectionToUse.section?.primaryInterests
            : false;
    }

    get bioExtraFields() {
        return attendeeStore.bioExtraFields;
    }

    get fullAddress() {
        const data = this.attendeeData;
        let parts = [data.address1, data.city, data.state, data.zip];

        parts = parts.filter((part) => {
            if (part && typeof part === "string") {
                return part;
            }
        });

        return parts.join(", ");
    }

    // lifecycle
    created() {
        this.init();
        eventHub.$on("BuefyModalBound", this.handleBuefyModalFixes);
    }

    beforeDestroy() {
        eventHub.$off("BuefyModalBound", this.handleBuefyModalFixes);
    }

    //methods
    async init() {
        if (!this.userInfo.id) {
            try {
                await this.$store.dispatch("getUserinfo");
            } catch (error) {
                console.error(error);
            }
        }

        const attendeePromises = [
            this.fetchAttendeeAvatar(),
            this.fetchAttendee()
        ];

        Promise.allSettled(attendeePromises).then(() => {
            this.isLoading = false;
        });
    }

    fetchAttendee() {
        return attendeeStore.getAttendee(this.attendeeApiOptions);
    }

    fetchAttendeeAvatar() {
        return attendeeStore.getAttendeeAvatarUrl(this.attendeeApiOptions);
    }

    showModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    showAvatarModal() {
        this.isAvatarModalOpen = true;
    }

    closeAvatarModal() {
        this.isAvatarModalOpen = false;
    }

    startFileUpload() {
        this.isLoading = true;

        eventHub.$emit("attendee-avatar-upload-started");

        this.handleFileUpload()
            .then(() => {
                eventHub.$emit("attendee-avatar-upload-success");
            })
            .catch(() => {
                eventHub.$emit("attendee-avatar-upload-error");
            })
            .finally(() => {
                eventHub.$emit("attendee-avatar-upload-done");
                this.isLoading = false;
            });
    }

    async handleFileUpload() {
        const options = {
            ...this.attendeeApiOptions,
            image: this.attendeeAvatarImage
        };

        try {
            await attendeeStore.uploadAttendeeAvatar(options);
        } catch (error) {
            throw new Error("Upload failed.");
        }

        try {
            await this.fetchAttendeeAvatar();
        } catch (error) {
            throw new Error("Get avatar failed.");
        }
    }

    handleProfileEditSubmit() {
        this.isLoading = true;
        eventHub.$emit("attendee-update-started");
        attendeeStore
            .putAttendee(this.attendeeApiOptions)
            .then(() => {
                eventHub.$emit("attendee-update-success");
            })
            .catch(() => {
                eventHub.$emit("attendee-update-error");
            })
            .finally(() => {
                eventHub.$emit("attendee-update-done");
                this.isLoading = false;
            });
    }

    openMessage() {
        this.$router.push({
            name: "Message-Center",
            params: {
                id: this.$props.id,
                name: this.attendeeDisplayName,
                companyName: this.attendeeData.companyName
                    ? this.attendeeData.companyName
                    : ""
            }
        });
    }

    handleBuefyModalFixes(el: HTMLElement) {
        a11yFixBuefyModalAriaAttrs(el);
    }
}
