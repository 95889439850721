





































































import { Component, Vue, Prop } from "vue-property-decorator";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

interface InterestObject {
    name: string;
    imageSrc?: string;
    iconName?: string;
}

@Component({
    components: {
        Swiper,
        SwiperSlide
    }
})
export default class AttendeePrimaryInterest extends Vue {
    @Prop({
        required: true,
        default: []
    })
    specialInterest!: Array<string>;
    publicPath = process.env.BASE_URL + "interest/";

    swiperOption = {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: true
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: 20
            },
            1024: {
                slidesPerView: 3,
                slidesPerGroup: 2,
                spaceBetween: 20
            },
            1336: {
                slidesPerView: 4,
                slidesPerGroup: 3,
                spaceBetween: 10
            }
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        }
    };

    // getters
    get primaryInterests() {
        const imgsrc = this.specialInterest
            .map((item: string) => {
                return this.$store.getters.getPrimaryInterests(item);
            })
            .sort((item: InterestObject, nxt: InterestObject) => {
                return item.name.localeCompare(nxt.name);
            });
        return imgsrc;
    }
}
